<template>
  <div>
    <h1>Pigs</h1>
    <p>
      Currently we work with Iberian pigs. The animals live outside. There is no barn or other shelter but we do
      provide them material to build their own.
    </p>
    <p>
      There is a lot to learn about Iberian pigs as they are quite a bit different than other breeds. Follow the
      links for more details:
    </p>

    <ul v-if="hasPorkMeatSales">
      <li>How we keep Iberian pigs (tbd)</li>
      <li><router-link :to="{ name: 'ibericoMeat' }">Learn about meat cuts</router-link></li>
      <li>What <em>Jamon Iberico de Bellota</em> is (tbd)</li>
    </ul>

    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-pigs.jpg">
      <img src="../../assets/images/pigs.jpg">
    </picture>

    <p>
      Pigs like to "make their bed" and then sleep as a huge pile
      of pigs to keep each other warm at night. These animals are adapted to this climate with temperatures ranging
      from 45C on a hot summer day to negative 15C on a cold winter day.
    </p>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-pigsStraw.jpg">
      <img src="../../assets/images/pigsStraw.jpg">
    </picture>
  </div>
</template>

<script>
import { setupFeatureFlags } from '@/library'

export default {
  name: 'PigIntroductionEN',

  setup() {
    return setupFeatureFlags()
  },

}
</script>
