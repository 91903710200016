<template>
  <div>
    <h2>Iberian Pork Is Special</h2>
    <div class="side-by-side">
      <img src="@/assets/images/porkMeat.jpg" style="margin-right: 1em" />
      <p>
        Unlike the "white swine", as people here call it, Iberian pork meat has a dark red color and from a distance
        can be confused with red meat from cattle. Besides genetics the main reason is that the Iberian pig is
        active the whole day. There is no barn with little space to move. Instead these animals are running around all
        the time. A pig can run faster than a human. The diet the pig is fed also makes a huge difference.
      </p>
    </div>
  </div>
</template>

<style scoped>
.side-by-side {
  display: grid;
  grid-template-columns: auto auto;
}

@media (max-width:440px) {
  .side-by-side {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
