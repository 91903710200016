<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <PigIntroductionEN />
      <PigBenefitsForSoilEN />
      <PigMontaneraEN />
      <PigPorkEN />

      <div v-if="hasListAnimals">
        <h2>Iberico Pigs Currently at Our Farm</h2>
        <p>
          Currently we keep {{ animalCount }} pigs on the farm.

          <span v-if="hasPorkMeatSales">If you are interested in buying meat for your family, just use the buy button
            where
            it appears.</span>
        </p>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Age</th>
              <th>Sex</th>
              <th v-if="hasPorkMeatSales">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="animal in animals" :key="animal.externalId">
              <td>{{ animal.externalId }}</td>
              <td>{{ animal.ageInMonths }} months</td>
              <td>{{ animal.sex }}</td>
              <td v-if="hasPorkMeatSales"><button v-if="animal.readyForSale" class="animal-buy"
                  @click.stop="buyForMeat(animal.animalId)">Buy</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import { setupFeatureFlags } from '@/library'
import { axiosInstance } from '@/library'
import PigIntroductionEN from './PigIntroductionEN.vue'
import PigBenefitsForSoilEN from './PigBenefitsForSoilEN.vue'
import PigMontaneraEN from './PigMontaneraEN.vue'
import PigPorkEN from './PigPorkEN.vue'

export default {
  name: "PigsEN",
  components: {
    Sidebar,
    PigIntroductionEN,
    PigBenefitsForSoilEN,
    PigMontaneraEN,
    PigPorkEN
  },

  data: () => ({
    animals: []
  }),

  computed: {
    animalCount () {
      return this.animals.length
    }
  },

  setup () {
    return setupFeatureFlags()
  },

  beforeMount () {
    this.getInventory()
  },

  methods: {
    getInventory () {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/animals/PIG/IBERICO`)
        .then((response) => {
          this.animals = response.data.animals
        })
    },

    buyForMeat (animalId) {
      this.$router.push({ name: 'ibericoMeat', params: { animalId } })
    },

  }

}
</script>

<style scoped>
TABLE {
  border-collapse: separate;
  border-spacing: 30px 0;
}

.side-by-side {
  display: grid;
  grid-template-columns: auto auto;
}

@media (max-width:440px) {
  .side-by-side {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
