<template>
  <div>
    <h2>Ecological Benefits for the Soil</h2>
    <p>
      The same way as wild boars our pigs are uprooting the soil in search of roots and small critters living in
      the soil. They are nature's plow and help water to infiltrate. They also eat critters which might be harmful
      to plants if there is too many.
    </p>
    <p>
      Further, pigs help us to integrate new seeds into the soil so that plants can grow. They do destroy a bit
      first but if moved to another area soon enough the benefit is bigger than the damage could ever be.
    </p>
  </div>
</template>
