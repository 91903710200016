<template>
  <div>
    <h2>Montanera - Acorn Diet</h2>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-montanera.jpg">
      <img src="../../assets/images/montanera.jpg">
    </picture>
    <p>
      Between the months of November to late February our oak trees (Quercus ilex sp. bellota) provide a lot of
      delicious and sweet acorns. During that time we do not feed the pigs any additional feed. The exception might
      be when we use a feed bag to lure them a bit when we need to handle them.
    </p>
    <p>
      The acorns and what else they can find is more than enough to allow them to grow fat.
    </p>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-12-14_13-01-08.jpg">
      <img src="../../assets/images/2022-12-14_13-01-08.jpg">
    </picture>
  </div>
</template>
